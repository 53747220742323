import { useSearchParams } from '@remix-run/react';
import { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from '~/utils/localStorage';

type ReferralCodeContextValue = [string | null, (v: string | null) => void];

export const ReferralCodeContext = createContext<ReferralCodeContextValue>([
  null,
  () => {},
]);

export function useReferralCodeContextValue(): ReferralCodeContextValue {
  const [searchParams] = useSearchParams();
  const rc = searchParams.get('rc');
  const [referralCode, storeReferralCode] = useLocalStorage<string>(
    'referralCode',
    rc,
  );

  // Store the referral code in local storage when it changes. We'll use the
  // most recent value supplied in the 'rc' query param (on any route).
  useEffect(() => {
    if (rc === 'clear') {
      storeReferralCode(null);
    } else if (rc) {
      storeReferralCode(rc);
    } else {
      return;
    }
  }, [rc, storeReferralCode]);

  return [referralCode, storeReferralCode] as const;
}

export function useReferralCode() {
  return useContext(ReferralCodeContext);
}
