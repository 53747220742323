import { useEffect, useState } from 'react';

export function useLocalStorage<T>(
  key: string,
  initialValue: T | null,
): [T | null, (value: T | null) => void] {
  const [value, setValue] = useState<T | null>(initialValue);

  const storeValue = (newValue: T | null) => {
    const json = localStorage.getItem(key);
    if (newValue === null && json) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
    setValue(newValue);
  };

  useEffect(() => {
    const setValueFromJson = (json: string | null) => {
      let newValue = null;
      try {
        newValue = json ? (JSON.parse(json) as T) : null;
      } catch (e) {
        console.error(e);
      }
      setValue(newValue);
    };

    const json = localStorage.getItem(key);
    if (!json && initialValue) {
      localStorage.setItem(key, JSON.stringify(initialValue));
    }
    if (json) {
      setValueFromJson(json);
    }
    const callback = (event: StorageEvent) => {
      if (event.key !== key) return;
      setValueFromJson(event.newValue);
    };
    window.addEventListener('storage', callback);
    return () => {
      window.removeEventListener('storage', callback);
    };
  }, [key, initialValue]);

  return [value, storeValue];
}
